<template>
    <div v-if="arrData" class="px-1">
        <table class="table table-sm table-hover">
            <tbody>
                <tr v-for="audit in arrData" :key="audit">
                    <td>
                        <div class="row">
                            <div class="col-8 mb-2">
                                <!-- <div>{{ audit.userId }}</div> -->
                                <div class="text-grey">{{ func.convDateTimeFormat(audit.date, 'text')}}</div>
                            </div>
                            <div class="col-4 mb-1 text-end">
                                <div>
                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'windows'">
                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                            <fa :icon="['fab', 'windows']" style="font-size: 25px" class="me-2" />
                                        </Popper>
                                    </span>
                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'linux'">
                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                            <fa :icon="['fab', 'linux']" style="font-size: 25px" class="me-2" />
                                        </Popper>
                                    </span>
                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'ubuntu'">
                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                            <fa :icon="['fab', 'ubuntu']" style="font-size: 25px" class="me-2" />
                                        </Popper>
                                    </span>
                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'redhat'">
                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                            <fa :icon="['fab', 'redhat']" style="font-size: 25px" class="me-2" />
                                        </Popper>
                                    </span>
                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'fedora'">
                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                            <fa :icon="['fab', 'fedora']" style="font-size: 25px" class="me-2" />
                                        </Popper>
                                    </span>
                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'centos'">
                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                            <fa :icon="['fab', 'centos']" style="font-size: 25px" class="me-2" />
                                        </Popper>
                                    </span>
                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'mac os'">
                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                            <fa :icon="['fab', 'apple']" style="font-size: 25px" class="me-2" />
                                        </Popper>
                                    </span>
                            
                                    <span v-if="audit.jBrowser.name.toLowerCase() === 'chrome'">
                                        <Popper class="popperDark" arrow hover :content="audit.jBrowser.name + ' ' + audit.jBrowser.version">
                                            <fa :icon="['fab', 'chrome']" style="font-size: 25px" class="me-2" />
                                        </Popper>
                                    </span>
                                    <span v-if="audit.jBrowser.name.toLowerCase() === 'firefox'">
                                        <Popper class="popperDark" arrow hover :content="audit.jBrowser.name + ' ' + audit.jBrowser.version">
                                            <fa :icon="['fab', 'firefox']" style="font-size: 25px" class="me-2" />
                                        </Popper>
                                    </span>
                                    <span v-if="audit.jBrowser.name.toLowerCase() === 'edge'">
                                        <Popper class="popperDark" arrow hover :content="audit.jBrowser.name + ' ' + audit.jBrowser.version">
                                            <fa :icon="['fab', 'edge']" style="font-size: 25px" class="me-2" />
                                        </Popper>
                                    </span>
                                    <span v-if="audit.jBrowser.name.toLowerCase() === 'mobile safari'">
                                        <Popper class="popperDark" arrow hover :content="audit.jBrowser.name + ' ' + audit.jBrowser.version">
                                            <fa :icon="['fab', 'safari']" style="font-size: 25px" class="me-2" />
                                        </Popper>
                                    </span>

                                    <span>
                                        <Popper class="popperDark" arrow hover :content="audit.jGeoLocation.city + ', ' + audit.jGeoLocation.state + ', ' + audit.jGeoLocation.countryName">
                                            <fa icon="location-dot" style="font-size: 25px" />
                                        </Popper>
                                    </span>
                                </div>
                                
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 mb-2">
                                <div v-if="audit.action === 'FOLDER_CREATED'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has created folder <span class="text-bold">{{ audit.jRemark.folderName }}</span>.
                                </div>
                                <div v-else-if="audit.action === 'FOLDER_CIRCULATED'">
                                    Folder <span class="text-bold">{{ audit.jRemark.folderName }}</span> is circulated by <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper>.
                                </div>
                                <div v-else-if="audit.action === 'FOLDER_SEALED'">
                                    Folder <span class="text-bold">{{ audit.jRemark.folderName }}</span> is sealed since all signees signed.
                                </div>
                                <!-- FOLDER_SHREDDED & FOLDER_SHRED_ALLAPPROVED only exists either one -->
                                <div v-else-if="audit.action === 'FOLDER_SHREDDED'">
                                    Folder <span class="text-bold">{{ audit.jRemark.folderName }}</span> is shredded by <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper>.
                                </div>
                                <div v-else-if="audit.action === 'FOLDER_SHRED_ALLAPPROVED'">
                                    Folder <span class="text-bold">{{ audit.jRemark.folderName }}</span> is shredded by <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> after get approval from all signees.
                                </div>
                                <div v-else-if="audit.action === 'FOLDER_SHRED_APPROVED'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has approved the shredding of folder <span class="text-bold">{{ audit.jRemark.folderName }}</span>.
                                </div>
                                <div v-else-if="audit.action === 'FOLDER_SHRED_CANCEL'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has cancelled shredding of folder <span class="text-bold">{{ audit.jRemark.folderName }}</span>.
                                </div>
                                <div v-else-if="audit.action === 'FOLDER_SHRED_CANCELREJECTED'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has cancelled the declined shredding of folder <span class="text-bold">{{ audit.jRemark.folderName }}</span>.
                                </div>
                                <div v-else-if="audit.action === 'FOLDER_SHRED_PENDING'">
                                    Folder <span class="text-bold">{{ audit.jRemark.folderName }}</span> is awaiting approval from the signee(s) for shredding.
                                </div>
                                <div v-else-if="audit.action === 'FOLDER_SHRED_STAGING'">
                                    Folder <span class="text-bold">{{ audit.jRemark.folderName }}</span> is marked as shred by <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper>.
                                </div>
                                <div v-else-if="audit.action === 'FOLDER_SHRED_REJECTED'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has declined the shredding of folder <span class="text-bold">{{ audit.jRemark.folderName }}</span>.
                                </div>
                                <div v-else-if="audit.action === 'FOLDER_TO_WIP' && audit.jRemark.folderOldStatus === 'SEALED'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has unsealed folder <span class="text-bold">{{ audit.jRemark.folderName }}</span> for revisioaudit.
                                </div>
                                <div v-else-if="audit.action === 'FOLDER_TO_WIP' && audit.jRemark.folderOldStatus === 'CIRCULATING'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has paused the distribution of folder <span class="text-bold">{{ audit.jRemark.folderName }}</span>.
                                </div>
                                
                                <div v-else-if="audit.action === 'DOCUMENT_ALLSIGNED'">
                                    Document <span class="text-bold">{{ audit.jRemark.documentName }}</span> in folder <span class="text-bold">{{ audit.jRemark.folderName }}</span> has been signed by all signees.
                                </div>
                                <div v-else-if="audit.action === 'DOCUMENT_APPROVED'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has approved document <span class="text-bold">{{ audit.jRemark.documentName }}</span> in folder <span class="text-bold">{{ audit.jRemark.folderName }}</span>.
                                </div>
                                <div v-else-if="audit.action === 'DOCUMENT_CANCELREJECTED'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has cancelled the declined to sign document <span class="text-bold">{{ audit.jRemark.documentName }}</span> in folder <span class="text-bold">{{ audit.jRemark.folderName }}</span>.
                                </div>
                                <div v-else-if="audit.action === 'DOCUMENT_REJECTED'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has declined to sign document <span class="text-bold">{{ audit.jRemark.documentName }}</span> in folder <span class="text-bold">{{ audit.jRemark.folderName }}</span>.
                                </div>
                                <div v-else-if="audit.action === 'DOCUMENT_SHRED'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has requested the shredding of document <span class="text-bold">{{ audit.jRemark.documentName }}</span> in folder <span class="text-bold">{{ audit.jRemark.folderName }}</span>.
                                </div>
                                <div v-else-if="audit.action === 'DOCUMENT_SHRED_ALLAPPROVED'">
                                    Document <span class="text-bold">{{ audit.jRemark.documentName }}</span> in folder <span class="text-bold">{{ audit.jRemark.folderName }}</span> is shredded by <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> after get approval from all signees.
                                </div>
                                <div v-else-if="audit.action === 'DOCUMENT_SHRED_STAGING'">
                                    Document <span class="text-bold">{{ audit.jRemark.documentName }}</span> in folder <span class="text-bold">{{ audit.jRemark.folderName }}</span> is marked as shred by <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper>.
                                </div>

                                <div v-else-if="audit.action === 'DOCUMENT_SHREDDED'">
                                    Document <span class="text-bold">{{ audit.jRemark.documentName }}</span> in folder <span class="text-bold">{{ audit.jRemark.folderName }}</span> is shredded by <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper>.
                                </div>
                                <div v-else-if="audit.action === 'DOCUMENT_SHRED_APPROVED'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has approved the shredding of document <span class="text-bold">{{ audit.jRemark.documentName }}</span> in folder <span class="text-bold">{{ audit.jRemark.folderName }}</span>.
                                </div>
                                <div v-else-if="audit.action === 'DOCUMENT_SHRED_CANCEL'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has cancelled shredding of document <span class="text-bold">{{ audit.jRemark.documentName }}</span> in folder <span class="text-bold">{{ audit.jRemark.folderName }}</span>.
                                </div>
                                <div v-else-if="audit.action === 'DOCUMENT_SHRED_CANCELREJECTED'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has cancelled the declined shredding of document <span class="text-bold">{{ audit.jRemark.documentName }}</span> in folder <span class="text-bold">{{ audit.jRemark.folderName }}</span>.
                                </div>
                                <div v-else-if="audit.action === 'DOCUMENT_SHRED_PENDING'">
                                    Document <span class="text-bold">{{ audit.jRemark.documentName }}</span> in folder <span class="text-bold">{{ audit.jRemark.folderName }}</span> is awaiting approval from the signee(s) for shredding.
                                </div>
                                <div v-else-if="audit.action === 'DOCUMENT_SHRED_REJECTED'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has declined the shredding of document <span class="text-bold">{{ audit.jRemark.documentName }}</span> in folder <span class="text-bold">{{ audit.jRemark.folderName }}</span>.
                                </div>
                                <div v-else-if="audit.action === 'DOCUMENT_SIGNED'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has signed document <span class="text-bold">{{ audit.jRemark.documentName }}</span> in folder <span class="text-bold">{{ audit.jRemark.folderName }}</span>.
                                </div>
                                <div v-else-if="audit.action === 'DOCUMENT_UPLOADED'">
                                    <Popper class="popperDark" arrow hover :content="audit.jRemark.userId"><span class="isTooltipsLabel">{{ audit.jRemark.displayName }}</span></Popper> has uploaded document <span class="text-bold">{{ audit.jRemark.documentName }}</span> into folder <span class="text-bold">{{ audit.jRemark.folderName }}</span>.
                                </div>
                                
                                <div v-else>
                                    <!-- <div class="small text-danger">OLD FORMAT: {{ audit.action }}</div> -->
                                    {{audit.description}}
                                </div>

                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import funcs from '@/functions/function'
import Popper from 'vue3-popper'

export default {
    name: 'Notification',
    props: [ 'data' ],
    emits: [],
    components: { Popper },
    setup (props, { emit }) {

        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const { getUserId } = useStore()
        const func = funcs

        const arrData = ref(props.data)

        onMounted(() => {
            // console.info('onMounted', JSON.stringify(arrData.value))
            // console.info('onMounted2', JSON.stringify(props.data))
        })

        return {
            store, route, router, func, getUserId, arrData
        }
    }
}
</script>

<style>
    
</style>